$logo-width: 175px;
$logo-margin-right: $spacer;
$fixed-header-margin-right: 100px;

@mixin hide-scrollbar() {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    /* Chrome */
    &::-webkit-scrollbar {
        display: none;
    }
}

/* disable body scrollbars */
html,
body {
    @include hide-scrollbar;
}

.lead,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    line-height: $lead-line-height;
}

h1 {
    margin-bottom: 2.25rem;
}

small,
.small {
    letter-spacing: 0.075em;
}

b,
strong {
    font-weight: $font-weight-bold;
}

.lead {
    margin-bottom: var(--eiv-richtext-paragraph-spacer);
}

.mosaic-tile-content p {
    // reset inside mosaic
    margin-bottom: $spacer;

    &:last-child {
        margin-bottom: 0;
    }
}

#eiv-top {
    position: relative;
    --#{$prefix}link-color-rgb: var(--#{$prefix}body-color-rgb);

    @include media-breakpoint-up(lg) {

        // scroll content underneath header
        &:before {
            content: " ";
            display: block;
            width: 100%;
            height: 90px;
            background-color: var(--#{$prefix}body-bg);
            position: fixed;
            z-index: 20;
        }
    }

    #fixed-header {

        @include media-breakpoint-up(lg) {
            position: fixed;
            z-index: 22;
            top: 0;
            padding-top: $spacer;
            padding-bottom: $spacer;
            padding-right: $grid-gutter-width * .5;
            padding-left: $grid-gutter-width * .5;
        }

        left: calc(((100% - var(--eiv-container-width)) * 0.5) + $logo-margin-right + $logo-width);
        right: calc(((100% - var(--eiv-container-width)) * 0.5) + $fixed-header-margin-right);

        #sticky-center {
            width: 100%;
            overflow: auto;

            .nav-pills {
                --#{$prefix}nav-pills-border-radius: #{$border-radius-pill};
            }

            .nav-link {
                white-space: nowrap;
                padding-top: 0.6rem;

                .plone-icon {
                    margin-bottom: .25rem;
                    margin-right: .25rem;
                }
            }

            @include hide-scrollbar;
        }

        .livesearch-results {
            right: 2rem;
            left: 2rem;
            width: auto;
            top: 2rem;
            font-size: $font-size-sm;
            max-height: calc(100vh - 100px);
            overflow: auto;

            .heading {
                font-size: inherit;
            }

            .list-group-item {
                border-bottom-color: transparent;

                a {
                    svg {
                        margin-right: .5rem;
                        vertical-align: sub;
                    }
                }

                &:first-child {
                    padding-top: $spacer * 2;
                }

                &:last-child {
                    border-bottom-color: var(--#{$prefix}list-group-border-color);
                    padding-bottom: $spacer * 2;
                }

                &.show-all a {
                    display: inline-block;
                    color: $primary;
                    border-bottom: solid 2px $primary;
                }
            }
        }

    }

    #scroll-header {
        position: relative;
        z-index: 21;

        @include media-breakpoint-down(lg) {
            background-color: var(--#{$prefix}body-bg);
        }

        #sticky-left {
            position: fixed;
            top: $spacer;
            z-index: 21;

            .logo img {
                width: 40px;
            }
        }

        #portal-logo {
            background-color: var(--#{$prefix}body-bg);
            max-width: $logo-width;
            margin-right: $logo-margin-right;
            padding-top: $spacer * 2;
            padding-bottom: $spacer;
            z-index: 21;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        #eiv-quicklinks {
            max-width: 100%;
            overflow: scroll;

            .nav {
                --#{$prefix}nav-link-font-weight: 600;
                margin-bottom: $spacer * .75;
                flex-wrap: nowrap;
                white-space: nowrap;
            }

            a.nav-link {
                padding-bottom: 0;

                &:hover {
                    @extend .active;
                }
            }

            @include hide-scrollbar;
        }

        .lang-selector-cart {
            margin-top: 31px;
        }

        #fixed-main-menu {
            width: 65px;

            &.fixed-pos {
                position: fixed;
                top: 0;
                right: calc((100% - var(--eiv-container-width) + $grid-gutter-width) * 0.5);
            }

            .main-menu {
                margin-top: 35px;
            }

            .close {
                display: none;
                margin-top: 17px;
                text-align: center;
            }
        }

    }

    #fixed-mobile-header {
        background-color: var(--#{$prefix}body-bg);

        #portal-logo-mobile img {
            max-width: 40px;
            height: auto;
        }

        #eiv-mobile-menu {
            width: 10rem;
        }

        .main-menu {
            margin: 0;
        }

        &.fixed-mobile {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 20;
        }
    }

    .main-menu {
        display: block;
        width: 34px;
        font-size: 10px;
        border-top:solid 2px rgb(var(--#{$prefix}body-color-rgb));
        font-weight: $font-weight-bolder;
        letter-spacing: 0.1rem;
        padding-top: $spacer * 1.5;

        &:before {
            content: " ";
            display: block;
            border-top:solid 2px rgb(var(--#{$prefix}body-color-rgb));
            margin: -1.2rem 0 .2rem 0;
        }
    }
}

#portal-top {
    position: relative;
    min-height: calc(100px + $spacer * 2.5);

    --eiv-container-width: 100%;

    /* calculate different container widths */
    @each $breakpoint, $container-max-width in map-remove($container-max-widths, 'sm', 'md') {
        @include media-breakpoint-up($breakpoint) {
            --eiv-container-width: #{$container-max-width};
        }
    }

    .nav {
        --#{$prefix}nav-link-color: rgb(var(--#{$prefix}body-color-rgb));
        margin: $spacer * .5;
    }

}

#eiv-menu {
    --#{$prefix}link-color-rgb: var(--#{$prefix}body-color-rgb);

    display: none;
    flex-direction: column;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    padding-left: calc(((100vw - var(--eiv-container-width)) * .5) + $grid-gutter-width * .5);
    padding-right: calc(((100vw - var(--eiv-container-width)) * .5) + $grid-gutter-width * .5);
    overflow-x: hidden;
    overflow-y: scroll;

    @include hide-scrollbar;

    @include media-breakpoint-up(lg) {
        top: 90px;
    }

    &.show {
        display: flex;
    }

    &.loading:before {
        @extend .spinner-border;
        content: " ";
        opacity: .5;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .list-group {
        --#{$prefix}list-group-bg: transparent;
        --#{$prefix}list-group-item-padding-x: 0;

        position: relative;
        left: 0;
        transition: left .5s, opacity .5s;

        @include media-breakpoint-up(xl) {
            .list-group-item:hover {
                --#{$prefix}list-group-border-color: #{$primary};
                color: $primary;

                .plone-icon {
                    transform: rotate(0);
                }

                .dropdown {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 0s, opacity 1.2s ease-out;
                }
            }
        }

        a.nav-link {
            --#{$prefix}nav-link-font-weight: $font-weight-bolder;

            .plone-icon {
                font-size: 2rem;

                @include media-breakpoint-up(xl) {
                    transform: rotate(-45deg);
                    transition: transform 0.2s;
                }
            }

        }

        &.list-group-flush .list-group-item:last-child {
            border-bottom-width: var(--#{$prefix}border-width);
        }

        .dropdown {
            @extend .dropdown-menu;

            --#{$prefix}dropdown-border-width: 0;
            --#{$prefix}dropdown-bg: transparent;
            --#{$prefix}dropdown-link-hover-bg: transparent;
            --#{$prefix}dropdown-link-hover-color: #{$primary};
            --#{$prefix}dropdown-item-padding-x: calc(#{$spacer} * 2);

            top: 0;
            left: 100%;
            display: block;
            visibility: hidden;
            opacity: 0;
            max-width: 100%;
        }

        &.mobile-open {
            width: 100%;
            left: calc(-100% - $grid-gutter-width);

            .list-group-item {
                position: static;
            }

            .dropdown.mobile-open {
                --#{$prefix}dropdown-item-padding-x: #{$grid-gutter-width};
                visibility: visible;
                opacity: 1;
                width: 100%;

                .dropdown-item {
                    white-space: normal;

                    &:active {
                        background-color: transparent;
                    }
                }

                .mobile-close {
                    margin-left: var(--#{$prefix}dropdown-item-padding-x);
                    padding: calc($spacer * .5) 0;
                    border-width: var(--#{$prefix}border-width);
                    border-color: var(--#{$prefix}border-color);
                    border-style: solid none;
                    margin-bottom: $spacer;
                    width: auto;
                }
            }
        }
    }

    >.close {
        display: none;
        position: absolute;
        right: $spacer;
        top: $spacer;

        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    .mobile-bottom {
        margin-bottom: $spacer * -2;
    }
}

body.eiv-menu-open {

    #portal-logo,
    #eiv-quicklinks,
    #scroll-right .lang-selector-cart,
    #scroll-right #fixed-main-menu .main-menu {
        display: none !important;
    }

    #eiv-top #scroll-header #fixed-main-menu .close {
        display: block
    }

    @include media-breakpoint-down(lg) {
        #eiv-top #fixed-header #sticky-center {
            background-color: rgb(var(--#{$prefix}body-bg-rgb));
        }
    }
}

// TinyMCE tweaks
.mce-content-body,
.tox-tinymce {
    .btn {
        user-select: all;
        cursor: default;
    }

    .pat-related-images {
        border: dashed 2px $gray-400;
        padding: $spacer * 2;
        margin: 5rem 0;
    }

    .accordion-header {
        border-top: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
        font-size: $font-size-base;
        background-color: #eee;
        margin-bottom: 0 !important;
        padding-bottom: $spacer !important;

        &:before {
            content: "Accordion header";
            font-size: 8px;
            display: block;
        }

        @include media-breakpoint-up(lg) {
            font-size: $font-size-lg;
        }
    }

    .accordion-body {
        border-bottom: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color) !important;
        background-color: #eee;
        margin-bottom: $spacer !important;

        &:before {
            content: "Accordion body";
            font-size: 8px;
            display: block;
        }
    }

    .my-auto {
        padding: 1rem;
        margin-bottom: 1rem;
        border: dashed 2px $gray-400;

        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-vertical" viewBox="0 0 16 16"><path d="M8.354 14.854a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 13.293V2.707L6.354 3.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 2.707v10.586l1.146-1.147a.5.5 0 0 1 .708.708l-2 2Z"/></svg>');
        background-position: 0 50%;
        background-repeat: no-repeat;
    }

    .eiv-zitat {
        padding: 1rem 1rem 1rem 1.5rem;
        border: dashed 2px $gray-400;

        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"/></svg>');
        background-position: 0 50%;
        background-repeat: no-repeat;
    }
}

.tox .tox-collection__item-label {
    line-height: inherit;
}

@include media-breakpoint-down(lg) {
    #portal-logo {
        display: none;
    }

    #eiv-top {
        --#{$prefix}border-radius-pill: 0;
    }

}


#portal-breadcrumbs {
    .breadcrumb {
        --#{$prefix}breadcrumb-font-size: #{$font-size-sm};
        --#{$prefix}breadcrumb-margin-bottom: 0;
        background-color: var(--#{$prefix}light-bg-subtle);
        margin-bottom: var(--eiv-richtext-heading-spacer);
        overflow-x: scroll;
        max-width: 100%;
        flex-wrap: nowrap;

        @include hide-scrollbar;

        .breadcrumb-item {
            flex-shrink: 0;
        }

        a {
            color: rgb(var(--#{$prefix}body-color-rgb));
        }
    }
}

// hide on startpage
body.section-startseite #portal-breadcrumbs {
    display: none;
}

.language-selector {
    .dropdown-toggle {
        z-index: $zindex-dropdown + 1;
    }

    .dropdown-menu {
        --#{$prefix}dropdown-item-padding-x: calc(#{$spacer} * .5);
        min-width: 3rem;
        padding-top: 2rem;
        padding-bottom: 0;
        margin-top: -2rem !important;
        font-size: $font-size-sm;
        background: transparent;

        >li {
            background-color: var(--#{$prefix}body-bg);

            .dropdown-item {
                border-top: var(--#{$prefix}dropdown-border-width) solid var(--#{$prefix}dropdown-border-color);
            }
        }
    }
}

// general .badge
.badge {
    --#{$prefix}badge-border-color: #{$primary};
    --#{$prefix}badge-border-radius: var(--#{$prefix}border-radius-pill);
    --#{$prefix}badge-border-width: 2px;
    --#{$prefix}badge-color: #{$primary};
    --#{$prefix}badge-font-size: #{$font-size-base};
    --#{$prefix}badge-font-weight: 600;
    background-color: var(--#{$prefix}body-bg);
    border: var(--#{$prefix}badge-border-width) var(--#{$prefix}border-style) var(--#{$prefix}badge-border-color);
    margin-bottom: $spacer * .5;
    padding-top: calc(var(--#{$prefix}badge-padding-y) * 1.75);

    &.outline-white {
        --#{$prefix}badge-border-color: #fff;
        --#{$prefix}badge-color: #fff;
        background-color: transparent;
    }

    &.m-0 {
        margin-bottom: 0;
    }

    &.badge-sm {
        --#{$prefix}badge-border-width: 1px;
        --#{$prefix}badge-color: #{$primary};
        --#{$prefix}badge-font-size: #{$font-size-sm};
        --#{$prefix}badge-font-weight: 400;
        --#{$prefix}badge-padding-y: 0.1rem;
    }
}

.badge.inverted,
.badge:hover:has(a),
a:hover:not(.summary-image) .badge,
.badge:has(a.filterItem.selected) {
    --#{$prefix}badge-color: var(--#{$prefix}body-bg);
    background-color: $primary;
}

.badge.black {
    --#{$prefix}badge-color: var(--#{$prefix}body-color);
    --#{$prefix}badge-border-color: var(--#{$prefix}body-color);
}

.badge a:hover,
a.filterItem.selected {
    --#{$prefix}link-color-rgb: var(--#{$prefix}body-bg-rgb);
}


h1,
h2,
h3 {
    .badge {
        --#{$prefix}badge-padding-x: .75rem;
        --#{$prefix}badge-padding-y: calc(var(--eiv-badge-font-size) * .15);
        --#{$prefix}badge-border-width: 3px;
        vertical-align: top;
        display: inline-block;
        font-size: calc(var(--eiv-badge-font-size) * .25 + 2.4vw);

        @include media-breakpoint-up(xl) {
            font-size: var(--eiv-badge-font-size);
        }
    }
}

h1 .badge {
    --eiv-badge-font-size: #{$h1-font-size * .65};
}

h2 .badge {
    --eiv-badge-font-size: #{$h2-font-size * .7};
}

h3 .badge {
    --eiv-badge-font-size: #{$h3-font-size * .75};
}

.lead .badge {
    vertical-align: top;
    margin-top: .4rem;
    @include font-size(1rem);
}

.filterContent a.badge,
.tags .badge {
    display: inline-block;
    white-space: normal;
    margin-bottom: $spacer * .5;
}

// limited width for event tags
.vevent .tags .badge {
    max-width: 13rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

a.youtube {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .bi,
    img {
        width: 20px;
        height: 20px;
        max-width: none;
    }

    img {
        /* SVG filter trick see https://stackoverflow.com/a/53336754/1145447 */
        /* EIV red */
        filter: invert(66%) sepia(100%) saturate(7490%) hue-rotate(8deg) brightness(93%) contrast(110%);
    }

    &:hover img {
        /* black */
        filter: invert(0%) sepia(100%) saturate(6%) hue-rotate(195deg) brightness(101%) contrast(98%);
    }
}

.arrow-link {
    width: 50px;
    height: 50px;
    border: 2px var(--#{$prefix}border-style) var(--#{$prefix}primary);
    border-radius: 50%;
    overflow: hidden;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;

    &:hover {
        background-color: $primary;
    }

    &.small {
        width: 35px;
        height: 35px;
    }

    &.white {
        border-color: white;

        .plone-icon {
            color: white;
        }

        &:hover {
            background-color: white;

            .plone-icon {
                color: $primary;
            }
        }
    }

    &.noborder {
        border: none;
        border-radius: 0;

        &:hover {
            background: transparent;

            .plone-icon {
                color: black;
            }
        }
    }

    &:not(.norotate) .plone-icon {
        transform: rotate(-45deg);
        transition: transform 0.2s;
    }

    &:hover .plone-icon {
        color: white;
        transform: rotate(0);
    }
}

.eiv-contact-person-viewlet,
.eiv-downloads-viewlet {
    margin: calc($spacer * 5) 0;

    h3 {
        margin-bottom: $spacer;
    }

    @include media-breakpoint-up(lg) {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;

        img {
            max-height: 335px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.eiv-partnerlogos-viewlet {

    .logoscroller {
        overflow-x: scroll;

        img {
            height: 50px;
            width: auto;
            filter: grayscale(1);

            &:hover {
                filter: grayscale(0);
            }
        }

        >* {
            flex: 0 0 auto;
        }

        @include hide-scrollbar;

    }
}

blockquote,
.well {
    background-color: var(--#{$prefix}light-bg-subtle);
    padding: calc(var(--eiv-richtext-paragraph-spacer) * .5);
    margin-bottom: var(--eiv-richtext-paragraph-spacer);

    >:last-child {
        margin-bottom: 0 !important;
    }
}

#portal-footer-wrapper {

    text-align: left;
    background-color: transparent;
    color: var(--#{$prefix}body-color);
    padding: 0;

    .list-group {
        --#{$prefix}list-group-border-color: var(--#{$prefix}body-color);
    }

    .plone-icon {
        font-size: 1.5rem;
        transform: rotate(-45deg);
        transition: transform 0.2s;
    }

    .list-group-item:hover .plone-icon {
        transform: rotate(0);
    }
}

#related-attachments {
    a {
        color: rgba(var(--#{$prefix}body-color-rgb));
    }

    td {
        padding: 1rem 0.5rem 1rem 0;

        &:last-child {
            padding-right: 0;
        }
    }
}

.eiv-zitat .small {
    color: $primary;
    border-bottom: solid 1px $primary;
}

#parent-fieldname-text,
#formPrologue,
#formEpilogue,
.mce-content-body:not(.mosaic-tile-content),
.tox-tinymce {

    .related-images-slider,
    .eiv-zitat {
        // outdent
        margin-top: var(--eiv-richtext-paragraph-spacer);
        margin-bottom: var(--eiv-richtext-paragraph-spacer);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: var(--eiv-richtext-heading-spacer);
    }

    p,
    >div {
        margin-bottom: var(--eiv-richtext-paragraph-spacer);
    }

    ol:not([style]),
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: var(--eiv-richtext-paragraph-spacer);

        li:not(.list-group-item) {
            margin-bottom: $spacer * .5;
            padding-left: $spacer * 2.5;

            &::before {
                color: $primary;
                display: inline-block;
                width: $spacer * 2;
                height: $spacer * 2;
                text-align: center;
                padding-top: $spacer * .2;
                margin-right: $spacer * .5;
                margin-left: $spacer * -2.5;
            }
        }
    }

    ul li:not(.list-group-item)::before {
        content: "•";
        font-size: $spacer * 2;
        line-height: 1;
        vertical-align: middle;
    }

    ol:not([style]) {
        counter-reset: section;

        li {

            &::before {
                counter-increment: section;
                content: counter(section);
                border: solid 1px $primary;
                border-radius: 50%;
            }
        }
    }

    ul.checks {
        list-style: none;

        li {
            padding-left: $spacer * 2;

            &::before {
                content: url('./++plone++bootstrap-icons/check-circle-fill.svg');
                filter: invert(57%) sepia(19%) saturate(1544%) hue-rotate(91deg) brightness(89%) contrast(80%);
                display: inline-block;
                margin-left: $spacer * -2;
                width: $spacer * 2;
                padding-top: 0;
            }
        }
    }
}

// accordion
#parent-fieldname-text,
#formPrologue,
#formEpilogue,
.mce-content-body:not(.mosaic-tile-content),
.tox-tinymce {
    .accordion {
        --#{$prefix}accordion-active-bg: var(--#{$prefix}body-bg);
        --#{$prefix}accordion-active-color: var(--#{$prefix}body-color);
        --#{$prefix}accordion-border-radius: 0;
        --#{$prefix}accordion-border-width: 0;
        --#{$prefix}accordion-btn-focus-border-color: transparent;
        --#{$prefix}accordion-btn-focus-box-shadow: none;
        --#{$prefix}accordion-btn-padding-x: 0;
        --#{$prefix}accordion-body-padding-x: 0;

        margin-bottom: var(--eiv-richtext-paragraph-spacer);

        .accordion-item {
            border-bottom: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);

            .accordion-header {
                font-weight: $font-weight-bold;
                margin-bottom: 0;
            }

            .accordion-body {
                padding-top: 0;

                > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// captioned figure
.captioned-figure {
    margin-bottom: var(--eiv-richtext-paragraph-spacer);

    figure.captioned {
        position: relative;
        margin-bottom: $spacer * .5;

        picture {
            display: inline-block;
            height: auto;
        }

        figcaption.image-copyright {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            font-size: $font-size-sm;
            background-color:rgba(var(--#{$prefix}body-bg-rgb), .6);
            padding: $spacer * .5 $spacer $spacer * .5 0;
            border-radius: var(--#{$prefix}border-radius-pill);

            width: 2.2rem;
            height: 2.2rem;
            overflow: hidden;
            white-space: nowrap;
            color: var(--#{$prefix}body-color);
            display: inline-flex;
            align-items: center;
            transition: width 1s;

            &::before {
                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-c-circle" viewBox="0 0 16 16"><path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512Z"/></svg>');
                width: 2.2rem;
                height: 2.2rem;
                display: inline-block;
                padding-top: $spacer*.6;
                flex-shrink: 0;
                text-align: center;
            }

            &:hover {
                width: auto;
            }
        }
    }

    .image-caption {
        color: rgb(var(--#{$prefix}body-color-rgb));
        font-size: $font-size-sm;
    }
}

// full width images
figure.size-large img {
    width: 100%;
    height: auto;
}

@include media-breakpoint-up(xl) {

    #content,
    .mce-content-body:not(.mosaic-tile-content),
    .tox-tinymce {

        >header p.lead {
            width: 75%;
        }

        &,
        h4,
        h5,
        h6 {
            font-size: $font-size-lg;
        }

        >footer {
            margin-top: 3rem;
        }
    }

    body.template-event_view #content>header p.lead {
        width: 100%;
    }

    body.template-tabular_view,
    body.template-edit:not(.mosaic-enabled) {
        #content {
            font-size: $font-size-base;
        }
    }

    body:not(.ajax_load) div:not(.modal-body) {

        #viewlet-below-content-body,
        #parent-fieldname-text {
            padding-left: 25%;
        }

        #eiv-contact-form,
        #eiv-registration-form {
            margin-left: 25%;
        }

        #parent-fieldname-text,
        #formPrologue,
        #formEpilogue {

            .related-images-slider,
            .eiv-zitat {
                // outdent
                margin-left: -33%;
            }

            .eiv-zitat {
                max-width: 100%;
            }
        }
    }

    .mosaic-tile-content,
    #parent-fieldname-text,
    #formPrologue,
    #formEpilogue {
        .accordion-button {
            font-size: $font-size-lg;
        }
    }

    .related-images-slider {
        margin-left: 10%;
    }

    .employee h3 {
        height: 4rem;
    }

    .embedded-video,
    #search-results-wrapper .searchResults {
        max-width: 75%;
        margin: 0 auto;
    }

    #parent-fieldname-text,
    #formPrologue,
    #formEpilogue {
        .embedded-video {
            max-width: none;
        }
    }
}

.related-images-slider {
    .slick-slide {
        .image-item {
            margin-right: $spacer;
            display: block;
        }

        img {
            height: 265px;
            width: auto;

            @include media-breakpoint-up(lg) {
                height: 565px;
            }
        }
    }

    // disable ajax-loader.gif
    .slick-loading .slick-list {
        background-image: none;
    }

    .slider-prev,
    .slider-next {
        width: 50px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .slider-next {
        margin-left: $spacer;
    }
}

#section-byline {
    margin-top: 1.5rem;
}

.event-date {
    color: rgba(var(--#{$prefix}body-color-rgb));
    border-color:var(--#{$prefix}border-color);

    &.badge {
        margin-bottom: 0;
    }
}

.text-white a {
    color: rgba(var(--#{$prefix}white-rgb));
}

.text-shadow {
    text-shadow: 1px 1px black;
}

/* template tile */
.template-tile {

    .text *:last-child {
        margin-bottom: 0;
    }

    .text-pos-bottom {
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: 100%;
    }

    .text-pos-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &.text-left-image-right-hero {
        @include media-breakpoint-up(xl) {
            // hero height with breadcrumbs
            height: calc(100vh - (100px + $spacer * 10.5)); // portal-top min-height + breadcrumbs height
        }

        >div {
            max-height: 100%;
        }

        .text {
            margin: var(--eiv-richtext-paragraph-spacer) var(--eiv-richtext-paragraph-spacer) var(--eiv-richtext-paragraph-spacer) 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.text-left-image-right,
    &.text-right-image-left {
        img {
            width: 100%;
            max-height: 650px;
            object-fit: cover;
        }
    }

    &.text-over-image {
        position: relative;
        overflow: hidden;

        .text {
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            z-index: 3;

            h3 a {
                color: #fff;
            }
        }

        .images {
            position: relative;
            z-index: 1;

            &:before {
                content: " ";
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &.text-flip-background {
        background-color: transparent;
        perspective: 1000px;
        position: relative;
        z-index: 20;
        /* make sure, flipped background is above all other tiles */

        .flip-box-inner {
            width: 100%;
            transition: transform 0.8s;
            transform-style: preserve-3d;
        }

        &.flipped .flip-box-inner {
            transform: rotateY(180deg);
        }

        /* Position the front and back side */
        .flip-box-fg,
        .flip-box-bg {
            width: 100%;
            -webkit-backface-visibility: hidden;
            /* Safari */
            backface-visibility: hidden;
        }

        .flip-box-bg {
            position: absolute;
            top: 0;
            transform: rotateY(180deg);
        }
    }
}

// existingcontent
.existingcontent-tile {
    &.text-over-image {
        position: relative;
        overflow: hidden;

        .text {
            color: #fff;
            z-index: 3;
            background-size: cover;

            h3 a {
                color: #fff;
            }

            &:after {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, .2);
            }

            >* {
                position: relative;
                z-index: 2;
            }
        }
    }
}

// special LG hero height without breadcrumbs
body.section-startseite .template-tile.text-left-image-right-hero {
    >div.text-pos-bottom {
        min-height: 33vh;
    }

    @include media-breakpoint-up(xl) {
        height: calc(100vh - (100px + $spacer * 2.5)); // portal-top min-height
    }
}

// OLD WP styles
.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

.success-box {

    &,
    a {
        color: $green;
    }
}

.danger-box {

    &,
    a {
        color: $primary;
    }
}

// gridlisting fix
.card-img-top.ratio {
    overflow: hidden;

    img {
        object-fit: cover;
        height: 100%;
    }
}

body.template-grid_listing {
    #parent-fieldname-text {
        margin-bottom: var(--eiv-richtext-paragraph-spacer);
    }

    article {
        margin-bottom: $spacer;

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: $spacer;

            a {
                color: rgb(var(--#{$prefix}body-color-rgb));
            }
        }

        .card {
            --#{$prefix}card-border-radius: 0;
            --#{$prefix}card-inner-border-radius: 0;
            --#{$prefix}card-spacer-y: calc(var(--eiv-richtext-heading-spacer) * .5);
            --#{$prefix}card-spacer-x: 0;

            /* align arrow links to bottom */
            .card-body {
                display: flex;
                flex-direction: column;

                .arrow-link {
                    margin-top: auto;
                    align-self: flex-end;
                }
            }
        }
    }

}

// summary view images
.summary-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// line clamp
.grid-item-description,
.tileBody .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

// footer logos
.footer-logos {

    img {
        height: 30px;
        width: auto;
        object-fit: contain;
        filter: grayscale(1);

        &:hover {
            filter: grayscale(0);
        }
    }


}

// bootstrap tweaks
.btn-primary {
    --#{$prefix}btn-color: var(--#{$prefix}body-bg);
}

.table:not(.pat-datatables) tr {
    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding-right: 0;
    }
}

// Overpass issue
.form-control,
input.btn,
a.rounded-pill {
    padding-top: 0.45rem;
}

body.portaltype-easyform #content-core>.form>#form,
#form-checkout,
form.eiv-form {
    --#{$prefix}border-color: transparent;

    .form-label,
    .form-check-label {
        font-size: $font-size-sm;
    }

    .form-control,
    .form-select {
        border-radius: var(--#{$prefix}border-radius-pill);

        &:focus {
            border-color: $primary;
            box-shadow: none;
        }

        &::placeholder {
            opacity: .5;
        }
    }

    textarea.form-control {
        border-radius: $spacer;
    }

    .formControls {
        background-color: inherit;
        position: static;
    }
}

form.eiv-form {
    .formControls {
        margin: 0;
    }
}

// easyform form
body.portaltype-easyform {
    #content-core>.form {
        background-color: var(--#{$prefix}light-bg-subtle);

        #formPrologue,
        #formEpilogue {
            background-color:var(--#{$prefix}body-bg);
        }

        @include media-breakpoint-up(xl) {
            margin-left: 25%;
        }

        >#form {
            .formControls {
                background-color: inherit;
                position: static;
            }

            >*:not(#formPrologue, #formEpilogue) {
                padding: calc($spacer * 3);
            }
        }
    }
}


// event listing
.vevent .tileHeadline {
    margin-bottom: 0;
}

// shopitem
body.template-shopitem h1 {
    font-size: $h2-font-size;
}

.cart_item_title {
    font-size: $h4-font-size;
}

.cart_sum_content td {
    padding-left: 0.25rem;
}

#cart-viewlet-details {
    position: fixed;
    display: none;
    background-color:var(--#{$prefix}body-bg);
    top: 90px;
    right: calc((100% - var(--plone-toolbar-width) - var(--eiv-container-width)) / 2);
    padding: $grid-gutter-width * .5;
    margin: $spacer;
    z-index: 100;

    &.show {
        display: block;
    }

    input.cart_item_count {
        width: 4rem;
    }

}

img.cart_item_preview_image {
    float: none;
    margin-left: 0;
    border:var(--#{$prefix}border-style) var(--#{$prefix}border-width) var(--#{$prefix}border-color);
}

#form-checkout {
    background-color: var(--#{$prefix}light-bg-subtle);
    padding: $spacer;

    h2 {
        font-size:$h3-font-size;
    }
}
