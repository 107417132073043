body.template-manage-viewlets {
    padding-top: 5rem;
}

/* fixed headers when logged in */
body.plone-toolbar-left {
    #eiv-top {

        #fixed-header,
        #fixed-mobile-header.fixed-mobile {
            left: var(--plone-toolbar-width-collapsed);
        }

        #fixed-header {
            left: calc((100% - var(--eiv-container-width) - var(--plone-toolbar-width-collapsed)) * 0.5 + var(--plone-toolbar-width-collapsed) + $logo-margin-right + $logo-width);
            right: calc((100% - var(--eiv-container-width) - var(--plone-toolbar-width-collapsed)) * 0.5 + $fixed-header-margin-right);
        }

        #scroll-header #fixed-main-menu.fixed-pos {
            right: calc((100% - var(--eiv-container-width) - var(--plone-toolbar-width-collapsed)) * 0.5);
        }
    }

    #eiv-menu {
        left: var(--plone-toolbar-width-collapsed);
        padding-left: calc(max((100% - var(--eiv-container-width) + $grid-gutter-width - var(--plone-toolbar-width-collapsed)), $grid-gutter-width) * 0.5);
        padding-right: calc(max((100% - var(--eiv-container-width) + $grid-gutter-width - var(--plone-toolbar-width-collapsed)), $grid-gutter-width) * 0.5);
    }

    &.plone-toolbar-left-expanded {
        #eiv-top {
            @include media-breakpoint-up(md) {

                #fixed-header,
                #fixed-mobile-header.fixed-mobile {
                    left: var(--plone-toolbar-width);
                }

                #scroll-header #fixed-main-menu.fixed-pos {
                    right: calc((100% - var(--eiv-container-width) - var(--plone-toolbar-width)) * 0.5 + $grid-gutter-width);
                }
            }

            #fixed-header {
                left: calc((100% - var(--eiv-container-width) - var(--plone-toolbar-width)) * 0.5 + var(--plone-toolbar-width) + $logo-margin-right + $logo-width);
                right: calc((100% - var(--eiv-container-width) - var(--plone-toolbar-width)) * 0.5 + $fixed-header-margin-right);
            }
        }

        #eiv-menu {
            @include media-breakpoint-up(md) {
                left: var(--plone-toolbar-width);
            }

            padding-left: calc(max(calc(100vw - var(--eiv-container-width) + $grid-gutter-width - var(--plone-toolbar-width)), $grid-gutter-width) * .5);
            padding-right: calc(max(calc(100vw - var(--eiv-container-width) + $grid-gutter-width - var(--plone-toolbar-width)), $grid-gutter-width) * .5);
        }
    }
}

body.template-folder_contents #content .badge {
    --#{$prefix}badge-font-size: .65rem;
    --#{$prefix}badge-font-weight: 400;
    --#{$prefix}badge-border-width: 1px;
    --#{$prefix}badge-border-radius: 5px;
    padding-top:var(--#{$prefix}badge-padding-y);
}

// hide actions menu in toolbar for folder_contents
body.template-folder_contents,
body.template-event_administration {

    #plone-contentmenu-actions,
    #plone-contentmenu-display {
        display: none;
    }
}

#edit-zone,
body.template-folder_contents #content,
body.template-event_administration #content,
body.template-edit:not(.mosaic-enabled) #content,
body.template-edit .select2-drop,
.pat-relateditems-result-title,
.pat-relateditems-item-title {

    &,
    .form-select,
    .form-control {
        font-size: $font-size-sm !important;
    }
}

body.template-event_administration #content,
body.template-folder_contents #content,
body.template-folder_contents .popover,
body.template-edit:not(.mosaic-enabled) #content,
body.template-edit .select2-drop {
    --#{$prefix}border-color: #ddd;

    font-family: var(--plone-toolbar-font);

    >h1,
    >header h1,
    h2 {
        font-size: $font-size-lg;
    }

    .form-label {
        font-weight: bold;
    }
}

// hide element on edit template
body.template-folder_contents,
body.template-event_administration,
body.template-edit:not(.mosaic-enabled) {

    >footer,
    #content>header .lead,
    #content>footer {
        display: none;
    }

}

.pat-structure table.dataTable th,
.pat-structure table.dataTable td {
    padding: 5px;
}

.pat-structure table.dataTable td {
    padding-bottom: 0;
}

.select2-container {
    display: block;
}

// related items
.pat-relateditems-container {
    button.mode {
        @extend .btn-sm;
    }

    .toolbar .path-wrapper {
        font-size: $font-size-sm;
    }
}

.pat-relateditems-dropdown.select2-drop-multi {
    .select2-results {
        display: flex;
        flex-flow: row wrap;

        .select2-result {
            border: var(--#{$prefix}border-color) solid var(--#{$prefix}border-width);
            border-radius: var(--#{$prefix}border-radius);
            margin: 0 .3rem .3rem 0;
        }
    }
}

.pat-relateditems-result-title,
.pat-relateditems-item-title {
    word-break: break-all;
    font-weight: bold;
    display: block;
    margin-bottom: .5rem;
}

.pat-relateditems-result-path {
    word-break: break-all;
}

@include media-breakpoint-up(lg) {

    .pat-relateditems .select2-choices>li.select2-search-choice,
    .pat-relateditems-dropdown.select2-drop-multi .select2-results>li.select2-result {
        // column items
        flex: 0 0 32%;
    }

    div.full-width-items .pat-relateditems .select2-choices>li.select2-search-choice,
    body.template-eiv-settings .pat-relateditems-dropdown.select2-drop-multi .select2-results>li.select2-result {
        flex: 1 1 100%;
    }
}

// lead image
// #form-widgets-ILeadImageBehavior-image {

//     input[type="file"],
//     .form-check {
//         display: none;
//     }

//     >span {
//         display: block;
//     }

//     &:after {
//         content: "Das Teaserbild muss über Verknüpfte Medien hochgeladen werden."
//     }
// }


// layout
textarea.layoutfield-field {
    @extend .form-control;
}

@media print {
    body.plone-toolbar-left {
        padding-left: 0 !important;
    }

    .container {
        max-width: 100% !important;
    }
}

body.template-edit #form .formControls {
    position: sticky;
    bottom: 0;
    z-index: 1020;
    background-color: white;
    padding: $spacer 0;
    border-top:solid 1px var(--#{$prefix}light-bg-subtle);
}
