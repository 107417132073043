// colors
$primary: #E62A29;
$green: #2FA45D;
$body-color: #1A171B;
$light-bg-subtle: #ECECEC;
$link-hover-color: $primary;

$link-decoration: none;
$small-font-size: 0.75rem;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: black;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$breadcrumb-divider-color}'/></svg>");
// $dropdown-toggle: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='currentColor' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

//$border-width:                2px;
$border-color:                $body-color;
$alert-border-width:          1px;

//FONT
$font-weight-base:            500;
$font-family-sans-serif:      "Overpass", sans-serif;
$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$lead-font-weight:            $font-weight-base;
$lead-font-size:              $font-size-base * 2;
$headings-font-weight:        700;
$headings-line-height:        1.2;
$headings-margin-bottom:      2.25rem;
$lead-line-height:            1.3;

$h1-font-size:                $font-size-base * 3.25;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

// BADGE
$badge-padding-y:             0.25rem;

// EIV
$eiv-paragraph-spacer: 5rem;   /* 80px */

// ACCORDION
$accordion-button-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$body-color}" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/></svg>');
$accordion-button-active-icon:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#{$body-color}" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/></svg>');

// SETTINGS
//$enable-rfs: false;
