/* overpass-300 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 300;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-300italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 300;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-regular - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 400;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-500 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-500italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 500;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-600 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 600;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-600italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 600;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-700 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-700italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 700;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-800 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 800;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-800italic - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 800;
  src: url('++plone++plonetheme.energieinstitut/fonts/overpass-v13-cyrillic_cyrillic-ext_latin_latin-ext-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}




  @font-face {
    font-family: Interstate;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('++plone++plonetheme.energieinstitut/fonts/interstate_mono-webfont.woff2') format('woff2');
  }
  @font-face {
    font-family: Interstate;
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url('++plone++plonetheme.energieinstitut/fonts/interstate_mono_bold-webfont.woff2') format('woff2');
  }
