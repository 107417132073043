.mosaic-grid-row-margin-top {
    margin-top: $spacer * 5;
}

.mosaic-grid-row-margin-bottom {
    margin-bottom: $spacer * 5;
}

.mosaic-grid-row-primary-background {
    padding-top: $spacer * 1.5;
    background-color: rgb(var(--#{$prefix}primary-rgb));
    color: white;

    a {
        color: white;
    }

    .badge {
        color: white;

        &,
        a {
            background-color: transparent;
            border-color: white;

            &:hover {
                background-color: white;
                color: $primary;
            }
        }
    }
}

/* multi column breakpoint */
.mosaic-grid-row-3-2-1 {
    @extend .row-cols-xl-3;
    @extend .row-cols-md-2;
    @extend .row-cols-1;

    .mosaic-resize-handle {
        // nothing to resize here
        display: none;
    }
}

/* fluid hero text margin left */
.mosaic-grid-row-fluid .template-tile.text-left-image-right-hero .text {
    margin-left: var(--eiv-richtext-paragraph-spacer);
}

body.template-layout,
body.mosaic-enabled {
    &.no-portlet-columns {
        .outer-wrapper>.container:not(#content-header) {

            /* fluid right only */
            .mosaic-grid-row-fluid-right {
                >.col {
                    padding-right: 0;
                }

                .template-tile.text-left-image-right-hero {
                    margin-right: 0;

                    >div:nth-child(2) {
                        // image column has no padding
                        padding: 0;
                    }
                }

                @each $breakpoint, $container-max-width in map-remove($container-max-widths, 'sm', 'md') {
                    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                        max-width: calc($container-max-width + (100% - $container-max-width) / 2);
                        margin-left: calc((100% - $container-max-width) / 2);

                        .template-tile.text-left-image-right-hero {
                            flex-wrap: nowrap;

                            // first column half of container-max-width,
                            >div:first-child {
                                width: calc($container-max-width / 2);
                            }

                            // second column streched to the right border of browser
                            >div:nth-child(2) {
                                width: 50vw;
                            }
                        }

                    }
                }
            }

            .mosaic-advanced {

                // tweak hero for advanced mode not to break in two lines
                .mosaic-grid-row-fluid-right {
                    .template-tile.text-left-image-right-hero {
                        >div:nth-child(2) {
                            width: 49.8vw;
                        }
                    }
                }
            }
        }


        &.plone-toolbar-left {
            .outer-wrapper>.container:not(#content-header) {

                /* fluid right only */
                .mosaic-grid-row-fluid-right {
                    @each $breakpoint, $container-max-width in map-remove($container-max-widths, 'sm', 'md') {
                        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                            // we add here 2px extra to make sure that it stays inline if advanced mode is enabled
                            max-width: calc($container-max-width + (100% - $container-max-width) / 2);

                            .template-tile.text-left-image-right-hero>div:nth-child(2) {
                                width: calc((100vw - var(--plone-toolbar-width-collapsed)) / 2);
                            }
                        }
                    }
                }
            }

            &.plone-toolbar-left-expanded {
                .outer-wrapper>.container:not(#content-header) {

                    /* fluid right only */
                    .mosaic-grid-row-fluid-right {
                        @each $breakpoint, $container-max-width in map-remove($container-max-widths, 'sm', 'md') {
                            @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                                .template-tile.text-left-image-right-hero>div:nth-child(2) {
                                    width: calc((100vw - var(--plone-toolbar-width)) / 2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mosaic-grid-row-no-margin>.mosaic-grid-cell {

    &,
    .row {
        --#{$prefix}gutter-x: 0;
    }
}

.mosaic-grid-row-masonry>.mosaic-grid-cell {
    margin-bottom: $spacer * 1.5;
}

body.mosaic-enabled>footer {
    animation: 1s mosaicBlurred ease-out forwards;
}

.mosaic-tile .summary-image img {
    height: 100%;
}

.mosaic-tile {
    padding-bottom: $grid-gutter-width;
}

.mosaic-tile-padding-bottom {
    padding-bottom: $spacer * 1.5;
}

.mosaic-panel .mosaic-selected-tile {
    background-color: transparent;
}

.contentlisting-tile {

    &.template-summary_view {
        &:not([class*="row-cols-"]) {

            @include media-breakpoint-down(xl) {
                flex-wrap: nowrap;
                overflow-x: scroll;

                >.col {
                    margin-right: var(--#{$prefix}gutter-x);
                }
            }

            >.col {
                /* one line summary cols horizontally scrolling below XL */
                flex: 0 0 auto;
                width: 75%;

                @include media-breakpoint-up(xl) {
                    /* 3 col listing above XL if nothing other defined */
                    flex: 0 0 auto;
                    width: 33.33333333%;
                }
            }
        }

        .tileHeadline a {
            color: $body-color;
        }

        .tileFooter {
            text-align: right;
        }
    }

    &.hide-description .tileBody,
    &.hide-tags .tags {
        display: none;
    }

    &.item-border-bottom .tileFooter {
        border-bottom: var(--#{$prefix}border-style) calc(var(--#{$prefix}border-width) * 2) var(--#{$prefix}border-color);
        padding-bottom: $spacer * 3;
    }

    &.image-flexible {
        img {
            object-fit: contain;
            width: auto;
        }
    }

    &.preview-border {
        .summary-image img {
            border:var(--#{$prefix}border-style) var(--#{$prefix}border-width) var(--#{$prefix}border-color);
        }
    }

}

.contentlisting-tile .load-more {
    width: 100%;
}

@include media-breakpoint-up(xl) {
    .collective-tiles-carousel .carousel-item img {
        max-height: 565px;
        width: auto;
    }
}
